import React, { useState, useCallback, useEffect } from 'react';
import { 
  Modal,
  TextField,
  ResourceList,
  ResourceItem,
  Text,
  BlockStack,
  Icon,
  Scrollable
} from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
const data = require('../../../common/data/base/_data');

const fetchDiscounts = async () => {
  try {
    const response = await data.get(`${Project.api}api/v1/merchants/discounts`);
    return response.discounts;
  } catch (error) {
    console.error('Error fetching discounts:', error);
    return [];
  }
};

const DiscountPicker = ({ onClose }) => {
  const [active, setActive] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [discounts, setDiscounts] = useState([]);
  const [filteredDiscounts, setFilteredDiscounts] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleSearchChange = useCallback((value) => {
    const lowercasedValue = value.toLowerCase();
    setSearchQuery(lowercasedValue);
    
    const results = discounts.filter((discount) =>
      discount.title.toLowerCase().includes(lowercasedValue)
    );

    setFilteredDiscounts(results);
  }, [discounts]);
  
  const handleSelectDiscount = (discount) => {
    onClose(discount);
    setActive(false);
  };

  const closeModal = () => {
    onClose(null);
    setActive(false);
  };

  useEffect(() => {
    const loadDiscounts = async () => {
      setLoading(true);
      const initialDiscounts = await fetchDiscounts();
      setDiscounts(initialDiscounts);
      setFilteredDiscounts(initialDiscounts);
      setLoading(false);
    };
    loadDiscounts();
  }, []);

  return (
    <Modal
      open={active}
      onClose={closeModal}
      title="Select a Discount"
      primaryAction={{
        content: 'Close',
        onAction: closeModal,
      }}
    >
      <Modal.Section>
        <TextField
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search discounts..."
          autoComplete="off"
        />
        {loading ? (
          <Text>Loading discounts...</Text>
        ) : (
          <Scrollable style={{ height: '400px' }}>
            <ResourceList
              resourceName={{ singular: 'discount', plural: 'discounts' }}
              items={filteredDiscounts}
              renderItem={(discount) => {
                const { id, title, summary } = discount;
                return (
                  <ResourceItem
                    id={id}
                    onClick={() => handleSelectDiscount(discount)}
                    accessibilityLabel={`Select discount code ${title}`}
                    media={<Icon source={ChevronRightIcon} color="subdued" />}
                  >
                    <BlockStack spacing="tight" align="start" blockAlignment="center">
                      <Text variant="headingSm" as="h6">{title}</Text>
                      <Text variant="bodySm" tone="subdued" as="p" truncate>
                        {summary}
                      </Text>
                    </BlockStack>
                  </ResourceItem>
                );
              }}
            />
          </Scrollable>
        )}
      </Modal.Section>
    </Modal>
  );
};

export default DiscountPicker;
