import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomePage from './components/pages/HomePage';
import QRCodesCreatePage from './components/pages/QRCodesCreatePage';
import QRCodesPublicPage from './components/pages/QRCodesPublicPage';
import NotFoundPage from './components/pages/NotFoundPage';
import NotFoundErrorPage from './components/pages/NotFoundErrorPage';
import ProtectedRoute from './components/service/ProtectedRoute'

export const routes = {
  'root': '/',
  'not-found': '/404',
  'home': '/home',
  'qrcodes-create': '/app/qrcodes/:uuid',
  'qrcodes-public': '/qrcodes/:uuid',
};


export default (
    <Routes>
      <Route path={routes['root']} element={<ProtectedRoute component={HomePage} />} />
      <Route path={routes['not-found']} element={<NotFoundErrorPage />} />
      <Route path={routes['home']} element={<ProtectedRoute component={HomePage} />} />
      <Route path={routes['qrcodes-create']} element={<ProtectedRoute component={QRCodesCreatePage} />} />
      <Route path={routes['qrcodes-public']} element={<QRCodesPublicPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
);
