import React, { createContext, useState, useEffect } from 'react';
const data = require('../../common/data/base/_data')

const QrCodeTemplatesContext = createContext();

const QrCodeTemplatesProvider = ({ children }) => {
  const [bodyTemplates, setBodyTemplates] = useState([]);
  const [eyeTemplates, setEyeTemplates] = useState([]);
  const [customTemplates, setCustomTemplates] = useState([]);
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const fetchQrCodeTemplates = async () => {
      try {
        const response = await data.get(`${Project.api}api/v1/templates/?page=1`);
        const templates = response.results;

        // Filter templates based on shape_type and type
        const filteredCustomTemplates = templates.filter(
          (template) => template.shape_type === 'full_qr_code'
        );

        const filteredBodyTemplates = templates.filter(
          (template) => template.shape_type === 'body'
        );

        const filteredEyeTemplates = templates.filter(
          (template) => template.shape_type === 'eye'
        );

        // Update state
        setCustomTemplates(filteredCustomTemplates);
        setBodyTemplates(filteredBodyTemplates);
        setEyeTemplates(filteredEyeTemplates);
      } catch (err) {
        console.error('Error fetching QR code templates:', err);
      }
    };

    fetchQrCodeTemplates();
  }, []);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const response = await data.get(`${Project.api}api/v1/images/list-logos/`);
        // Update state
        setLogos(response);
      } catch (err) {
        console.error('Error fetching logos:', err);
      }
    };

    fetchLogos();
  }, []);

  return (
    <QrCodeTemplatesContext.Provider value={{ 
        bodyTemplates, setBodyTemplates,
        eyeTemplates, setEyeTemplates,
        customTemplates, setCustomTemplates,
        logos, setLogos
    }}>
      {children}
    </QrCodeTemplatesContext.Provider>
  );
};

export { QrCodeTemplatesProvider, QrCodeTemplatesContext };
