import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
const data = require('../../../common/data/base/_data')


export default function QRCode() {
    const [qrCode, setQrCode] = useState({});
    const { id: qrCodeId } = useParams();

      useEffect(() => {
        const fetchQrCode = async () => {
          try {
            const response = await data.get(`${Project.api}api/v1/qrcodes/${qrCodeId}/`);
      
            setQrCode({
              destinationUrl: response.data.destination_url,
              ...response.data,
            });
          } catch (error) {
            console.error("Error fetching QR code:", error);
          }
        };
      
        fetchQrCode();
      }, [qrCodeId]);      

  return (
    <>
      <h1>{qrCode.title}</h1>
      <img src={`data:image/png;base64,${qrCode.b64_QR}`} alt={`QR Code for product`} />
    </>
  );
}
