import React, { createContext, useState, useEffect } from "react";
const data = require('../../common/data/base/_data');

export const MerchantContext = createContext();

export const MerchantProvider = ({ children }) => {
  const [merchant, setMerchant] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMerchant = async () => {
      try {
        const response = await data.get(`${Project.api}api/v1/merchants/profile`);
        setMerchant(response);
      } catch (error) {
        console.error("Error fetching merchant profile:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMerchant();
  }, []);

  return (
    <MerchantContext.Provider
      value={{
        merchant,
        setMerchant,
        loading,
      }}
    >
      {children}
    </MerchantContext.Provider>
  );
};
