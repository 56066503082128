import Project from 'common/project';
import { authenticatedFetch } from '@shopify/app-bridge/utilities';
import createApp from '@shopify/app-bridge';

const urlParams = new URLSearchParams(window.location.search);
const shopOrigin = urlParams.get('shop');
const host = urlParams.get('host');

const app = createApp({
  apiKey: Project.shopifyApiKey,
  host: host,
  shopOrigin: shopOrigin,
  forceRedirect: true,
});

const fetchInternal = authenticatedFetch(app);

const getQueryString = (params) => {
  const esc = encodeURIComponent;
  return Object.keys(params)
    .map((k) => `${esc(k)}=${esc(params[k])}`)
    .join('&');
};

module.exports = {
  _request(method, _url, data, headers = {}) {
    const options = {
      headers: {
        'Accept': 'application/json',
        ...headers,
      },
      method,
      timeout: 60000,
    };

    const isInternal = _url.startsWith(Project.api);

    // If `data` is FormData, do not set Content-Type manually
    if (!(data instanceof FormData) && method !== 'get') {
      options.headers['Content-Type'] = 'application/json; charset=utf-8';
    }

    if (this.token && !isInternal) {
      options.headers.AUTHORIZATION = `Bearer ${this.token}`;
    }

    let url = _url;
    if (data) {
      if (method === 'get') {
        const qs = getQueryString(data);
        url += url.indexOf('?') !== -1 ? `&${qs}` : `?${qs}`;
      } else if (data instanceof FormData) {
        // For file uploads, directly use FormData without stringifying
        options.body = data;
      } else {
        options.body = JSON.stringify(data);
      }
    } else if (method === 'post' || method === 'put') {
      options.body = '{}';
    }

    const fetchFunction = isInternal ? fetchInternal : fetch;

    return fetchFunction(url, options)
      .then((response) => this.status(response, !isInternal))
      .then((response) => {
        // always return json
        let contentType = response.headers.get('content-type');
        if (!contentType) {
          contentType = response.headers.get('Content-Type');
        }
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json();
        }
        return {};
      })
      .then((response) => {
        return response;
      });
  },
  delete(url, data, headers) {
    return this._request('delete', url, data, headers);
  },

  get(url, data, headers) {
    return this._request('get', url, data || null, headers);
  },

  post(url, data, headers) {
    return this._request('post', url, data, headers);
  },

  put(url, data, headers) {
    return this._request('put', url, data, headers);
  },

  setToken(_token) {
    // set the token for future requests
    this.token = _token;
  },

  status(response, isExternal) {
    // handle ajax requests
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    }
    // if (!isExternal && response.status === 401) {
    //   // AppActions.setUser(null);
    // }
    return Promise.reject(response);
  },

  token: '',

  type: '',
};
