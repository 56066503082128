import * as Yup from 'yup';

const QRCodeSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),

  destinationType: Yup.string()
    .oneOf(['product', 'collection', 'checkout', 'website'], 'Invalid destination type')
    .required('Destination type is required'),

  productId: Yup.string().when('destinationType', {
    is: (destinationType) => destinationType === 'product' || destinationType === 'checkout',
    then: (schema) => schema.required('Product ID is required'),
  }),

  collectionId: Yup.string().when('destinationType', {
    is: 'collection',
    then: (schema) => schema.required('Collection ID is required'),
  }),

  customUrl: Yup.string().when('destinationType', {
    is: 'website',
    then: (schema) =>
      schema
        .required('Custom URL is required')
        .url('Enter a valid URL'),
  }),

  backColorHex: Yup.string()
    .matches(/^#[0-9A-F]{6}$/i, 'Invalid HEX color code')
    .required('Background color is required'),

  fillColorHex: Yup.string()
    .matches(/^#[0-9A-F]{6}$/i, 'Invalid HEX color code')
    .required('Fill color is required'),

  moduleDrawerType: Yup.string().required('Body shape is required'),
  eyeDrawerType: Yup.string().required('Eye shape is required'),

});

export default QRCodeSchema;
