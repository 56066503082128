import React, { useContext, useEffect } from "react";
import { MerchantContext } from "../../context/merchant";


const ProtectedRoute = ({ component: Component, ...props }) => {
  const { merchant, loading } = useContext(MerchantContext);

  useEffect(() => {
    if (!loading && merchant && !merchant.has_active_subscription) {
      const shopHandle = merchant?.shop_domain.replace("https://", "").split(".")[0];
      const pricingPageUrl = `https://admin.shopify.com/store/${shopHandle}/charges/${Project.shopifyAppHandle}/pricing_plans`;
      window.parent.location.href = pricingPageUrl;
    }
  }, [merchant, loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    !loading && merchant && merchant.has_active_subscription ? (
      <Component {...props} />
    ) : null
  );
};

export default ProtectedRoute;
