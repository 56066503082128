import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import {
  Card,
  Bleed,
  Button,
  ChoiceList,
  Divider,
  EmptyState,
  InlineStack,
  InlineError,
  Layout,
  Page,
  Text,
  TextField,
  Thumbnail,
  BlockStack,
  PageActions,
  ColorPicker,
  hsbToHex,
  hexToRgb,
  rgbToHsb,
  Box,
  Icon,
  DropZone, 
  Banner,
} from "@shopify/polaris";
import { CornerSquareIcon, ImageIcon, SaveIcon } from "@shopify/polaris-icons";
import { useParams, useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
const data = require('../../../common/data/base/_data')
import { DiscountPicker } from '../discount'
import { useModal } from '../../context/modal';
import { QrCodeTemplatesContext } from '../../context/templates';

import QRCodeSchema from './QRCodeSchema'
// import SaveTemplateModal from '../templates/SaveTemplateModal'


function hexToHsb(hex) {
  const rgb = hexToRgb(hex);
  const hsb = rgbToHsb(rgb);
  return hsb;
}


function truncate(str, { length = 25 } = {}) {
  if (!str) return "";
  if (str.length <= length) return str;
  return str.slice(0, length) + "…";
}


const initialState = {
  title: '',
  destinationType: 'product',
  productId: '',
  productVariantId: '',
  productTitle: '',
  productHandle: '',
  productAlt: '',
  productImage: '',
  collectionId: '',
  collectionTitle: '',
  collectionHandle: '',
  collectionAlt: '',
  collectionImage: '',
  discountId: '',
  discountCode: '',
  discountSummary: '',
  customUrl: '',
  fillColorHbs: hexToHsb("#000000"),
  backColorHbs: hexToHsb("#ffffff"),
  fillColorHex: "#000000",
  backColorHex: "#ffffff",
  moduleDrawerType: '',
  eyeDrawerType: '',
  logo: ''
}

export default function QRCodeForm() {
  const { uuid: qrCodeId } = useParams();
  const [initialValues, setInitialValues] = useState(initialState);
  const [qrCode, setQrCode] = useState({});
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState(null);
  const navigate = useNavigate();
  const showModal = useModal();

  // color picker display
  const [showFillColorPicker, setShowFillColorPicker] = useState(false)
  const [showBackColorPicker, setShowBackColorPicker] = useState(false)
  const fillColorPickerRef = useRef(null);
  const backColorPickerRef = useRef(null);

  // Templates State
  const { bodyTemplates, eyeTemplates } = useContext(QrCodeTemplatesContext);
  // const [selectedCustomTemplate, setSelectedCustomTemplate] = useState(null);
  const [selectedBodyShape, setSelectedBodyShape] = useState(null);
  const [selectedEyeShape, setSelectedEyeShape] = useState(null);
  const [hoveredTemplate, setHoveredTemplate] = useState(null);

  // const [active, setActive] = useState(false);
  // const toggleSaveTemplateModal = () => setActive((prev) => !prev);

  const [uploadedLogo, setUploadedLogo] = useState('');
  const { logos } = useContext(QrCodeTemplatesContext);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [hoveredLogo, setHoveredLogo] = useState(null);

  // Close the ColorPicker when clicking outside the preview div
  useEffect(() => {
    function handleClickOutside(event) {
      if (fillColorPickerRef.current && !fillColorPickerRef.current.contains(event.target)) {
        setShowFillColorPicker(false);
      }
      if (backColorPickerRef.current && !backColorPickerRef.current.contains(event.target)) {
        setShowBackColorPicker(false);
      }

    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [fillColorPickerRef, backColorPickerRef]);

  useEffect(() => {
    if (qrCodeId === 'new') {
      setLoading(false);
      return;
    }
    const fetchQrCode = async () => {
      try {
        const response = await data.get(`${Project.api}api/v1/qrcodes/${qrCodeId}/`);
        setQrCode({
          destinationUrl: response.destination_url,
          ...response
        });

        setInitialValues({
          title: response.title,
          destinationType: response.destination_type || "product",
          productId: response.product?.id || "",
          productHandle: response.product?.handle || "",
          productTitle: response.product?.title || "",
          productVariantId: response.product_variant_id || "",
          productAlt: response.product?.image?.alt || "",
          productImage: response.product?.image?.url || "",
          collectionId: response.collection?.id || "",
          collectionHandle: response.collection?.handle || "",
          collectionTitle: response.collection?.title || "",
          collectionAlt: response.collection?.image?.alt || "",
          collectionImage: response.collection?.image?.url || "",
          discountId: response.discount?.id || "",
          discountCode: response.discount?.title || "",
          discountSummary: response.discount?.summary || "",
          customUrl: response.custom_url || "",
          fillColorHbs: hexToHsb(response.fill_color || "#000000"),
          backColorHbs: hexToHsb(response.back_color || "#ffffff"),
          fillColorHex: response.fill_color || "#000000",
          backColorHex: response.back_color || "#ffffff",
          moduleDrawerType: response.module_drawer_type || "",
          eyeDrawerType: response.eye_drawer_type || "",
          logo: response.logo ? response.logo.split('/').pop() : "",
        });
        setUploadedLogo(response.logo)
      } catch (error) {
        console.error("Error fetching QR code data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQrCode();
  }, [qrCodeId]);

  useEffect(() => {
    if (qrCode?.uuid) {
      const matchedEyeTemplate = eyeTemplates.find(
        (template) => template.eye_drawer_type === qrCode.eye_drawer_type
      );

      const matchedBodyTemplate = bodyTemplates.find(
        (template) => template.module_drawer_type === qrCode.module_drawer_type
      );

      const logoUrl = qrCode.logo || '';
      const logoIdentifier = logoUrl
        ? logoUrl.substring(logoUrl.lastIndexOf('/') + 1).split('.').slice(0, -1).join('.')
        : '';
      
      const matchedLogo = logos.find(
        (logo) => logo.identifier === logoIdentifier
      );
      

      if (matchedBodyTemplate) {
        setSelectedBodyShape(matchedBodyTemplate || null);
      }

      if (matchedEyeTemplate) {
        setSelectedEyeShape(matchedEyeTemplate || null);
      }

      if (matchedLogo) {
        setSelectedLogo(matchedLogo || null);
      }
    }
  }, [qrCode?.uuid]);

  const handleTemplateSelect = (template, setFieldValue) => {

    switch (template.shape_type) {
      // case "full_qr_code":
      //   setSelectedCustomTemplate(template);
      //   // set colors
      //   setFieldValue('fillColorHex', template.fill_color);
      //   setFieldValue('backColorHex', template.back_color);
      //   setFieldValue('fillColorHbs', hexToHsb(template.fill_color));
      //   setFieldValue('backColorHbs', hexToHsb(template.back_color));
      //   // set body shape
      //   if(!selectedBodyShape) {
      //     setFieldValue('moduleDrawerType', template.module_drawer_type);  
      //   }
      //   // set eye shape
      //   if(!selectedEyeShape) {
      //     setFieldValue('eyeDrawerType', template.eye_drawer_type);  
      //   }
      //   break;
      case "body":
        setSelectedBodyShape(template)
        setFieldValue('moduleDrawerType', template.module_drawer_type);
        break;

      case "eye":
        setSelectedEyeShape(template)
        setFieldValue('eyeDrawerType', template.eye_drawer_type);
        break;

      default:
        throw new Error(`Invalid Template shape_type`);
    }

  };

  const handleQRCodeSave = async (values, { setSubmitting, setErrors, resetForm }) => {
    const requestData = {
      title: values.title,
      destination_type: values.destinationType,
      product_id: values.productId,
      product_variant_id: values.productVariantId,
      product_handle: values.productHandle,
      product_title: values.productTitle,
      custom_url: values.customUrl,
      collection_id: values.collectionId,
      collection_handle: values.collectionHandle,
      collection_title: values.collectionTitle,
      discount_code: values.discountCode,
      discount_id: values.discountId,
      discount_summary: values.discountSummary,
      fill_color: values.fillColorHex,
      back_color: values.backColorHex,
      module_drawer_type: values.moduleDrawerType,
      eye_drawer_type: values.eyeDrawerType,
      logo: values.logo
    };

    try {
      const isNewQRCode = qrCodeId === 'new';
      const endpoint = isNewQRCode 
        ? `${Project.api}api/v1/qrcodes/` 
        : `${Project.api}api/v1/qrcodes/${qrCodeId}/`;
  
      const response = isNewQRCode
        ? await data.post(endpoint, requestData)
        : await data.put(endpoint, requestData);
  
      setQrCode({
        ...response,
        destinationUrl: response.destination_url,
      });
  
      resetForm({ values });
  
      if (isNewQRCode) {
        navigate(`/app/qrcodes/${response.uuid}`);
      }
    } catch (error) {
      if (error.status === 400) {
        const errorData = await error.json();
        setFormError(
          errorData.non_field_errors?.join(', ') || 
          'An error occurred while saving the QR code'
        );
      } else {
        setFormError('An unexpected error occurred while saving the QR code');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleQRCodeDelete = async () => {
    try {
      await data.delete(`${Project.api}api/v1/qrcodes/${qrCode.uuid}/`);
      navigate('/');
    } catch (error) {
      console.error("Error deleting QR code:", error);
    }
  };

  // const handleTemplateDelete = async (template_uuid) => {
  //   try {
  //     await data.delete(`${Project.api}api/v1/qrcode-templates/${template_uuid}/`);
  //     setCustomTemplates(prevTemplates => prevTemplates.filter(template => template.uuid !== template_uuid));
  //     if (selectedCustomTemplate?.uuid === template_uuid) {
  //       setSelectedCustomTemplate(null);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting QR code:", error);
  //   }
  // };

  const selectProduct = async (setFieldValue, validateField, setTouched) => {
    const products = await window.shopify.resourcePicker({
      type: "product",
      action: "select",
    });

    if (products) {
      const { images, id, variants, title, handle } = products[0];
      setFieldValue('productId', id);
      setFieldValue('productVariantId', variants[0].id);
      setFieldValue('productTitle', title);
      setFieldValue('productHandle', handle);
      setFieldValue('productAlt', images[0]?.altText);
      setFieldValue('productImage', images[0]?.originalSrc);

      setTimeout(() => {
        validateField('productId');
      }, 0);
    }
    setTouched({ productId: true });
  };

  const selectCollection = async (setFieldValue, validateField, setTouched) => {
    const collections = await window.shopify.resourcePicker({
      type: "collection",
      action: "select",
    });

    if (collections) {
      const { id, title, handle, image } = collections[0];
      setFieldValue('collectionId', id);
      setFieldValue('collectionTitle', title);
      setFieldValue('collectionHandle', handle);
      setFieldValue('collectionAlt', image?.altText);
      setFieldValue('collectionImage', image?.originalSrc);

      setTimeout(() => {
        validateField('collectionId');
      }, 0);
    }
    setTouched({ collectionId: true });
  };

  const selectDiscount = async (setFieldValue) => {
    const discount = await showModal(<DiscountPicker />);

    if (discount) {
      const { title, summary, id } = discount;
      setFieldValue('discountCode', title);
      setFieldValue('discountSummary', summary);
      setFieldValue('discountId', id);
    }
  };

  const handleUpload = useCallback(async (event, setFieldValue) => {
    const logo = event.target.files[0]; // Get the selected file
    if (logo) {
  
      try {
        const formData = new FormData();
        formData.append('image', logo);

        const response = await data.post(`${Project.api}api/v1/images/upload-logo/`, formData);
        const imagePath = response.image;
        const imageName = imagePath.split('/').pop();

        setUploadedLogo(URL.createObjectURL(logo));
        setFieldValue('logo', imageName);
        setSelectedLogo(null)
      } catch (error) {
        console.error("Error uploading file:", error.response ? error.response.data : error.message);
      }
    }
  }, []);

  const handleLogoClick = (setFieldValue, identifier) => {
    const logo = logos.find(logo => logo.identifier === identifier);
    setUploadedLogo(logo.url)
    setFieldValue('logo', identifier)
    setSelectedLogo(logo)
  };

  const handleRemoveLogo = (setFieldValue) => {
    setUploadedLogo('');
    setFieldValue('logo', '')
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={QRCodeSchema}
      onSubmit={handleQRCodeSave}
    >
      {({
        values,
        setValues,
        setFieldValue,
        validateField,
        setTouched,
        dirty,
        isValid,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form>
          <Page>
            <ui-title-bar title={qrCode.uuid ? "Edit QR code" : "Create new QR code"}>
              <button type="button" variant="breadcrumb" onClick={() => navigate("/")}>
                QR codes
              </button>
            </ui-title-bar>
            <Layout>
              <Layout.Section>
                <BlockStack gap="500">
                  {/* qrcoes title */}
                  <Card>
                    <BlockStack gap="300">
                      <Text as={"h1"} variant="headingMd">Title</Text>
                      <Field name="title">
                        {({ field }) => (
                          <>
                            <TextField
                              {...field}
                              helpText="Only store staff can see this title"
                              label="title"
                              labelHidden
                              autoComplete="off"
                              value={values.title}
                              onChange={(newValue) => setFieldValue('title', newValue)}
                            />
                            {errors.title && touched.title ? (
                              <InlineError message={errors.title} fieldID="title" />
                            ) : null}
                          </>
                        )}
                      </Field>
                    </BlockStack>
                  </Card>

                  {/* qrcodes destination */}
                  <Card>
                    <BlockStack gap="300">
                      {values.destinationType === 'product' && (
                        <>
                          <InlineStack align="space-between">
                            <Text as={"h1"} variant="headingMd">Product</Text>
                            {values.productId ? (
                              <Button variant="plain" onClick={() => selectProduct(setFieldValue, validateField, setTouched)}>
                                Change product
                              </Button>
                            ) : null}
                          </InlineStack>
                          {values.productId ? (
                            <BlockStack>
                              <InlineStack blockAlign="center" gap="500">
                                <Thumbnail
                                  source={values.productImage || ImageIcon}
                                  alt={values.productAlt}
                                />
                                <Text 
                                  as="span" 
                                  variant="headingMd" 
                                  fontWeight="semibold"
                                  textDecorationLine={
                                    qrCode?.uuid && 
                                    qrCode?.product?.is_deleted && 
                                    qrCode?.product?.id === values.productId
                                      ? "line-through" 
                                      : undefined
                                  }
                                >
                                  {values.productTitle}
                                </Text>
                              </InlineStack>
                              {qrCode?.uuid && qrCode?.product?.is_deleted && qrCode?.product?.id === values.productId && (
                                <div style={{ marginTop: '8px' }}>
                                  <Text as="p" variant="bodySm" tone="critical">
                                    The selected product is no longer available. Please update the QR code destination.
                                  </Text>
                                </div>
                              )}
                            </BlockStack>
                          ) : (
                            <BlockStack gap="200">
                              <Button onClick={() => selectProduct(setFieldValue, validateField, setTouched)} id="select-product">
                                Select product
                              </Button>
                            </BlockStack>
                          )}
                          {errors.productId && touched.productId && (
                            <InlineError message={errors.productId} fieldID="productId" />
                          )}                          
                        </>
                      )}

                      {values.destinationType === 'checkout' && (
                        <>
                          <InlineStack align="space-between">
                            <Text as={"h1"} variant="headingMd">Product</Text>
                            {values.productId ? (
                              <Button variant="plain" onClick={() => selectProduct(setFieldValue, validateField, setTouched)}>
                                Change product
                              </Button>
                            ) : null}
                          </InlineStack>
                          {values.productId ? (
                            <BlockStack>
                              <InlineStack blockAlign="center" gap="600">
                                <Thumbnail
                                  source={values.productImage || ImageIcon}
                                  alt={values.productAlt}
                                />
                                <Text 
                                  as="span" 
                                  variant="headingMd" 
                                  fontWeight="semibold"
                                  textDecorationLine={
                                    qrCode?.uuid && 
                                    qrCode?.product?.is_deleted && 
                                    qrCode?.product?.id === values.productId
                                      ? "line-through" 
                                      : undefined
                                  }                              
                                >
                                  {values.productTitle}
                                </Text>
                              </InlineStack>
                              {qrCode?.product?.is_deleted && qrCode?.product?.id === values.productId && (
                                <div style={{ marginTop: '8px' }}>
                                  <Text as="p" variant="bodySm" tone="critical">
                                    The selected product is no longer available. Please update the QR code destination.
                                  </Text>
                                </div>
                              )}
                              {!qrCode?.product?.is_deleted && qrCode?.product?.selected_variant?.is_deleted && qrCode?.product?.id === values.productId && (
                                <div style={{ marginTop: '8px' }}>
                                  <Text as="p" variant="bodySm" tone="critical">
                                    The selected variant is no longer available. Please update the QR code destination..
                                  </Text>
                                </div>
                              )}
                            </BlockStack>
                          ) : (
                            <BlockStack gap="600">
                              <Button onClick={() => selectProduct(setFieldValue, validateField, setTouched)} id="select-product">
                                Select product
                              </Button>
                            </BlockStack>
                          )}
                          {errors.productId && touched.productId && (
                            <InlineError message={errors.productId} fieldID="productId" />
                          )}

                          {/* Add a spacer or custom margin for extra space between sections */}
                          <div style={{ marginBottom: '1rem' }} />

                          {values.discountCode ? (
                            <BlockStack>
                              <InlineStack align="space-between" gap="600" blockAlignment="center" vertical>
                                <BlockStack>
                                  <Text 
                                      variant="headingSm" 
                                      as="h6"
                                      textDecorationLine={
                                        qrCode?.uuid && 
                                        qrCode?.discount?.is_deleted && 
                                        qrCode?.discount?.id === values.discountId
                                          ? "line-through" 
                                          : undefined
                                      }                              
                                      >
                                      {truncate(values.discountCode, { length: 25 })}
                                    </Text>
                                    {values.discountSummary && (
                                      <Text 
                                        variant="bodySm" 
                                        tone="subdued" 
                                        as="p" truncate
                                        textDecorationLine={
                                          qrCode?.uuid && 
                                          qrCode?.discount?.is_deleted && 
                                          qrCode?.discount?.id === values.discountId
                                            ? "line-through" 
                                            : undefined
                                        }                              
                                      >
                                        {truncate(values.discountSummary, { length: 50 })}
                                      </Text>
                                    )}
                                </BlockStack>
                                <Button variant="plain" onClick={() => selectDiscount(setFieldValue)}>
                                  Change discount
                                </Button>
                              </InlineStack>
                              {qrCode?.uuid && qrCode?.discount?.is_deleted && qrCode?.discount?.id === values.discountId && (
                                <div style={{ marginTop: '8px' }}>
                                  <Text as="p" variant="bodySm" tone="critical">
                                    The selected discount is no longer available. Please update the QR code destination.
                                  </Text>
                                </div>
                              )}
                            </BlockStack>
                          ) : (
                            <BlockStack gap="600">
                              <Button onClick={() => selectDiscount(setFieldValue)} id="select-discount">
                                Select Discount
                              </Button>
                            </BlockStack>
                          )}
                        </>
                      )}

                      {values.destinationType === 'collection' && (
                        <>
                          <InlineStack align="space-between">
                            <Text as={"h1"} variant="headingMd">Collection</Text>
                            {values.collectionId ? (
                              <Button variant="plain" onClick={() => selectCollection(setFieldValue, validateField, setTouched)}>
                                Change collection
                              </Button>
                            ) : null}
                          </InlineStack>
                          {values.collectionId ? (
                            <BlockStack>
                              <InlineStack blockAlign="center" gap="500">
                                <Thumbnail
                                  source={values.collectionImage || ImageIcon}
                                  alt={values.collectionAlt}
                                />
                                <Text 
                                  as="span" 
                                  variant="headingMd" 
                                  fontWeight="semibold"
                                  textDecorationLine={
                                    qrCode?.uuid && 
                                    qrCode?.collection?.is_deleted && 
                                    qrCode?.collection?.id === values.collectionId
                                      ? "line-through" 
                                      : undefined
                                  }                              
                                >
                                  {values.collectionTitle}
                                </Text>
                              </InlineStack>
                              {qrCode?.uuid && qrCode?.collection?.is_deleted && qrCode?.collection?.id === values.collectionId && (
                                <div style={{ marginTop: '8px' }}>
                                  <Text as="p" variant="bodySm" tone="critical">
                                    The selected collection is no longer available. Please update the QR code destination.
                                  </Text>
                                </div>
                              )}
                            </BlockStack>
                          ) : (
                              <BlockStack gap="200">
                                    <Button onClick={() => selectCollection(setFieldValue, validateField, setTouched)} id="select-collection">
                                      Select collection
                                    </Button>
                              </BlockStack>
                          )}
                          {errors.collectionId && touched.collectionId && (
                            <InlineError message={errors.collectionId} fieldID="collectionId" />
                          )}
                        </>
                      )}

                      {values.destinationType === "website" && (
                        <Field name="customUrl">
                          {({ field }) => (
                            <>
                              <Text as={"h1"} variant="headingMd">
                                URL
                              </Text>
                              <TextField
                                {...field}
                                placeholder="https://example.com"
                                value={values.customUrl}
                                onChange={(newValue) => setFieldValue("customUrl", newValue)}
                              />
                              {errors.customUrl && touched.customUrl ? (
                                <InlineError message={errors.customUrl} fieldID="customUrl" />
                              ) : null}
                            </>
                          )}
                        </Field>
                      )}

                      <Bleed marginInlineStart="200" marginInlineEnd="200">
                        <Divider />
                      </Bleed>

                      <InlineStack gap="500" align="space-between" blockAlign="start">
                        <Field name="destinationType">
                          {({ field }) => (
                            <>
                              <ChoiceList
                                {...field}
                                title="Scan destination"
                                choices={[
                                  { label: "Link to product page", value: "product" },
                                  { label: "Link to collection page", value: "collection" },
                                  {
                                    label: "Link to checkout page with product in the cart",
                                    value: "checkout",
                                  },
                                  {
                                    label: "Link to website",
                                    value: "website",
                                  },
                                ]}
                                selected={[values.destinationType]}
                                onChange={(destinationType) => {
                                  setValues((prevValues) => ({
                                    ...prevValues,
                                    productId: '',
                                    productVariantId: '',
                                    productTitle: '',
                                    productHandle: '',
                                    productAlt: '',
                                    productImage: '',
                                    collectionId: '',
                                    collectionTitle: '',
                                    collectionHandle: '',
                                    collectionAlt: '',
                                    collectionImage: '',
                                    discountCode: '',
                                    discountId: '',
                                    discountSummary: '',
                                    customUrl: '',                                  
                                    destinationType: destinationType[0],
                                  }));
                                }}
                              />
                              {errors.destinationType && touched.destinationType ? (
                                <InlineError
                                  message={errors.destinationType}
                                  fieldID="destinationType"
                                />
                              ) : null}
                            </>
                          )}
                        </Field>
                        {qrCode.destinationUrl ? (
                          <Button
                            variant="plain"
                            url={qrCode.destinationUrl}
                            target="_blank"
                          >
                            Go to destination URL
                          </Button>
                        ) : null}
                      </InlineStack>
                    </BlockStack>
                  </Card>

                  {/* qrcodes color */}
                  <Card title="QR Code Colors" sectioned>
                    <BlockStack gap="600">
                      {/* Foreground Color */}
                      <BlockStack gap="300">
                        <Text as="h1" variant="headingMd">Foreground Color</Text>
                        <InlineStack blockAlign="center">
                          <Field name="fillColorHex">
                            {({ field }) => (
                              <TextField
                                {...field}
                                labelHidden
                                value={values.fillColorHex}
                                onChange={(newHexValue) => {
                                  setTouched({ fillColorHex: true });
                                  setFieldValue("fillColorHex", newHexValue);
                                  if (/^#[0-9A-F]{6}$/i.test(newHexValue)) {
                                    setFieldValue("fillColorHbs", hexToHsb(newHexValue));
                                    setTimeout(() => {
                                      validateField('fillColorHex');
                                    }, 0);                          
                                  }
                                }}
                                autoComplete="off"                               
                              />
                            )}
                          </Field>
                          <div
                            style={{
                              marginLeft: '5px',
                              width: '40px',
                              height: '35px',
                              borderRadius: '20%',
                              backgroundColor: values.fillColorHex,
                              border: '1px solid #d9d9d9',
                              cursor: 'pointer',
                            }}
                            onClick={() => setShowFillColorPicker(!showFillColorPicker)}
                          />
                          {showFillColorPicker && (
                            <div
                              ref={fillColorPickerRef}
                              style={{
                                position: 'absolute',
                                left: 'calc(45% + 15px)',
                                top: '0',
                                backgroundColor: 'white',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                zIndex: 1100,
                              }}
                            >
                              <ColorPicker
                                color={values.fillColorHbs}
                                onChange={(newColor) => {
                                  setFieldValue("fillColorHbs", newColor);
                                  setFieldValue("fillColorHex", hsbToHex(newColor));
                                }}
                                allowAlpha
                              />
                            </div>
                          )}
                        </InlineStack>
                        {errors.fillColorHex && touched.fillColorHex && (
                          <InlineError message={errors.fillColorHex} fieldID="fillColorHex" />
                        )}
                      </BlockStack>

                      {/* Background Color */}
                      <BlockStack gap="300">
                        <Text as={"h1"} variant="headingMd">Background Color</Text>
                        <InlineStack blockAlign="center">
                          <Field name="backColorHex">
                            {({ field }) => (
                              <TextField
                                {...field}
                                labelHidden
                                value={values.backColorHex}
                                onChange={(newHexValue) => {
                                  setTouched({ backColorHex: true });
                                  setFieldValue("backColorHex", newHexValue);
                                  if (/^#[0-9A-F]{6}$/i.test(newHexValue)) {
                                    setFieldValue("backColorHbs", hexToHsb(newHexValue));
                                    setTimeout(() => {
                                      validateField('backColorHex');
                                    }, 0);
                                  }
                                }}
                                autoComplete="off"
                              />
                            )}
                          </Field>
                          <div
                            style={{
                              marginLeft: '5px',
                              width: '40px',
                              height: '35px',
                              borderRadius: '20%',
                              backgroundColor: values.backColorHex,
                              border: '1px solid #d9d9d9',
                              cursor: 'pointer',
                            }}
                            onClick={() => setShowBackColorPicker(!showBackColorPicker)}
                          />
                          {showBackColorPicker && (
                            <div
                              ref={backColorPickerRef}
                              style={{
                                position: 'absolute',
                                left: 'calc(45% + 15px)',
                                top: '0',
                                backgroundColor: 'white',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                zIndex: 1100,
                              }}
                            >
                              <ColorPicker
                                color={values.backColorHbs}
                                onChange={(newColor) => {
                                  setFieldValue("backColorHbs", newColor);
                                  setFieldValue("backColorHex", hsbToHex(newColor));
                                }}
                                allowAlpha
                              />
                            </div>
                          )}
                        </InlineStack>
                        {errors.backColorHex && touched.backColorHex && (
                          <InlineError message={errors.backColorHex} fieldID="backColorHex" />
                        )}
                      </BlockStack>
                    </BlockStack>
                  </Card>

                  <Card title="Choose a logo for your QR Code">
                    <BlockStack gap="300">
                      <Text as="h1" variant="headingMd">Logo</Text>
                      <div style={{ display: 'flex', height: '235px', justifyContent: 'space-between' }}>
                        <div style={{ width: '50%' }}>
                          {logos.map((logo) => (
                            <div
                              key={logo.identifier}
                              onClick={() => handleLogoClick(setFieldValue, logo.identifier)}
                              onMouseEnter={() => setHoveredLogo(logo.identifier)}
                              onMouseLeave={() => setHoveredLogo(null)}
                              style={{
                                position: 'relative',
                                transition: 'all 0.3s',
                                width: '60px',
                                height: '60px',
                                margin: '0 8px 8px 0',
                                background: '#fff',
                                float: 'left',
                                opacity: 0.99,
                                padding: '6px',
                                borderWidth: selectedLogo?.identifier === logo.identifier ? '3px' : '3px',
                                borderRadius: '4px',
                                borderColor: selectedLogo?.identifier === logo.identifier ? '#049ce1' : '#ffffff',
                                borderStyle: 'solid',
                                textAlign: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <i 
                                className={`sprite-logo-${logo.identifier}`}
                                style={{ 
                                  fontSize: '24px',
                                  display: 'block',
                                  backgroundImage: `url(${Project.spritesheetLogos})`,
                                  backgroundRepeat: 'no-repeat',
                                  transform: 'scale(0.4) translate(-72px, -72px)',
                                }}
                              />

                              {hoveredLogo === logo.identifier && (
                                <div
                                  style={{
                                    position: 'absolute',  // Position this overlay inside the current logo box
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(224, 224, 224, 0.7)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: '4px',
                                    zIndex: 10,
                                    gap: '10px',
                                  }}
                                >
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
                          <div
                            style={{
                              width: '150px',
                              height: '150px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: uploadedLogo ? '#ffffff' : '#f4f6f8',
                              border: uploadedLogo ? undefined : '1px dashed #dfe3e8',
                              marginBottom: '15px',
                            }}
                          >
                            {uploadedLogo ? (
                              <img
                                src={uploadedLogo}
                                alt="Uploaded logo"
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                            ) : (
                              <Text as="p" fontWeight="bold">No logo</Text>
                            )}
                          </div>

                          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {uploadedLogo && (
                              <Button
                                fullWidth
                                onClick={() => handleRemoveLogo(setFieldValue)}
                              >
                                Remove file
                              </Button>
                            )}
                            {/* Upload Button */}
                            <Button
                              fullWidth
                              onClick={() => document.getElementById('logo-upload-input').click()}
                              variant="primary"
                            >
                              Upload Logo
                            </Button>

                            <input
                              id="logo-upload-input"
                              type="file"
                              accept="image/*"
                              style={{ display: 'none' }}
                              onChange={(event) => handleUpload(event, setFieldValue)}
                            />
                          </div>
                        </div>
                      </div>

                    </BlockStack>
                  </Card>

                {/* eye templates */}
                <Card title="Eye Shape" sectioned>
                    <BlockStack gap="300">
                      <Text as="h1" variant="headingMd">Eye Shape</Text>
                      <InlineStack gap="300">
                        {eyeTemplates.map((template) => (
                          <Box
                            key={template.uuid}
                            padding="200"
                            onClick={() => handleTemplateSelect(template, setFieldValue)}
                            onMouseEnter={() => setHoveredTemplate(template.uuid)}
                            onMouseLeave={() => setHoveredTemplate(null)}
                            style={{
                              cursor: 'pointer',
                              borderWidth: selectedEyeShape?.uuid === template.uuid ? '3px' : '3px',
                              borderStyle: 'solid',
                              borderRadius: '4px',
                              borderColor: selectedEyeShape?.uuid === template.uuid ? '#049ce1' : '#ffffff',
                              width: '88px',
                              height: '88px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'relative',
                              overflow: 'hidden',
                              padding: '12px 0 0 2px',
                            }}
                          >
                            <i 
                              className={`sprite-${template.title}`}
                              style={{ 
                                backgroundImage: `url(${Project.spritesheetEyeTemplates})`,
                                backgroundRepeat: 'no-repeat',
                                transform: 'scale(0.7)',
                              }}
                            />
                            {hoveredTemplate === template.uuid && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '0',
                                  left: '0',
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: 'rgba(224, 224, 224, 0.7)',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '4px',
                                  zIndex: 10,
                                  gap: '10px',
                                }}
                              >
                              </div>
                            )}
                          </Box>
                        ))}
                      </InlineStack>
                      {errors.eyeDrawerType && touched.eyeDrawerType && (
                        <InlineError message={errors.eyeDrawerType} fieldID="eyeDrawerType" />
                      )}
                    </BlockStack>
                </Card>

                {/* body templates */}
                <Card title="Body Shape" sectioned>
                  <BlockStack gap="300">
                    <Text as="h1" variant="headingMd">Body Shape</Text>
                    <InlineStack gap="300">
                      {bodyTemplates.map((template) => (
                        <Box
                          key={template.uuid}
                          padding="200"
                          onClick={() => handleTemplateSelect(template, setFieldValue)}
                          onMouseEnter={() => setHoveredTemplate(template.uuid)}
                          onMouseLeave={() => setHoveredTemplate(null)}
                          style={{
                            cursor: 'pointer',
                            borderWidth: selectedBodyShape?.uuid === template.uuid ? '3px' : '3px',
                            borderStyle: 'solid',
                            borderRadius: '4px',
                            borderColor: selectedBodyShape?.uuid === template.uuid ? '#049ce1' : '#ffffff',
                            width: '120px',
                            height: '120px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            overflow: 'hidden',
                          }}
                        >
                            <i 
                              className={`sprite-${template.title}`}
                              style={{ 
                                backgroundImage: `url(${Project.spritesheetBodyTemplates})`,
                                backgroundRepeat: 'no-repeat',
                                transform: 'scale(0.7)',
                              }}
                            />
                          {hoveredTemplate === template.uuid && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(224, 224, 224, 0.7)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '4px',
                                zIndex: 10,
                                gap: '10px',
                              }}
                            >
                            </div>
                          )}
                        </Box>
                      ))}
                    </InlineStack>
                    {errors.moduleDrawerType && touched.moduleDrawerType && (
                      <InlineError message={errors.moduleDrawerType} fieldID="moduleDrawerType" />
                    )}
                  </BlockStack>
                </Card>

                {/* custom templates */}
                {/* <Card title="Custom Templates" sectioned>
                  <BlockStack gap="300">
                    <Text variant="headingMd">Custom Template</Text>
                    <InlineStack gap="300">
                      {customTemplates.map((template) => (
                        <Box
                          key={template.uuid}
                          padding="200"
                          onClick={() => handleTemplateSelect(template, setFieldValue)}
                          onMouseEnter={() => setHoveredTemplate(template.uuid)}
                          onMouseLeave={() => setHoveredTemplate(null)}
                          style={{
                            cursor: 'pointer',
                            borderWidth: '2px',
                            borderStyle: 'solid',
                            borderRadius: '8px',
                            borderColor: selectedCustomTemplate?.uuid === template.uuid ? 'black' : '#d9d9d9',
                            width: '120px',
                            height: '120px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            position: 'relative',
                            padding: '4px',
                          }}
                        >
                          <img
                            src={`data:image/png;base64,${template.b64_QR}`}
                            alt="QR code template"
                            style={{
                              width: '100%',
                              height: '90%',
                              objectFit: 'cover',
                            }}
                          />
                          <Text variant="bodyMd" as="p">
                            {template.title}
                          </Text>
                          {hoveredTemplate === template.uuid && (
                            <div
                              style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(224, 224, 224, 0.7)',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '8px',
                                zIndex: 10,
                                gap: '10px',

                              }}
                            > */}
                              {/* TODO: find better UX for delete btn */}
                              {/* {template.merchant_uuid && (
                                <Button
                                  icon={DeleteIcon}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleTemplateDelete(template.uuid);
                                  }}
                                  plain // Icon-only button
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    // right: '10px',
                                    backgroundColor: 'transparent',
                                    color: '#d9534f',
                                    padding: '0',
                                    cursor: 'pointer',
                                  }}
                                  onMouseEnter={(e) => (e.currentTarget.style.color = '#c9302c')} // Darker red on hover
                                  onMouseLeave={(e) => (e.currentTarget.style.color = '#d9534f')} // Original color when not hovered
                                />
                              )} */}
                            {/* </div>
                          )}
                        </Box>
                        ))}
                    </InlineStack>
                  </BlockStack>
                </Card> */}

                </BlockStack>
              </Layout.Section>
              <Layout.Section variant="oneThird">
                <Card>
                  <Text as={"h2"} variant="headingLg">
                    QR code
                  </Text>
                  {qrCode.uuid ? (
                    <EmptyState image={`data:image/png;base64,${qrCode.b64_QR}`} imageContained={true} />
                  ) : (
                    <EmptyState image="">
                      Your QR code will appear here after you save
                    </EmptyState>
                  )}
                  <BlockStack gap="300">
                    <Button
                      disabled={!qrCode?.b64_QR}
                      variant="primary"
                      onClick={() => {
                        const link = document.createElement('a');
                        link.href = `data:image/png;base64,${qrCode.b64_QR}`;
                        link.download = 'qrcode.png';
                        link.click();
                      }}
                    >
                      Download
                    </Button>
                    {/* <Button
                      disabled={!qrCode.uuid}
                      icon={<Icon source={SaveIcon} />}
                      onClick={toggleSaveTemplateModal}
                    >
                      Save template
                    </Button>
                    <SaveTemplateModal
                      active={active}
                      toggleModal={toggleSaveTemplateModal}
                      setTemplates={setCustomTemplates}
                      parentFormState={values}
                    /> */}
                  </BlockStack>
                </Card>
                {formError && (
                  <InlineError message={formError} fieldID="formError" />
                )}
              </Layout.Section>
              <Layout.Section>
                <PageActions
                  secondaryActions={[
                    {
                      content: "Delete",
                      disabled: !qrCode.uuid || !qrCode,
                      destructive: true,
                      outline: true,
                      onAction: handleQRCodeDelete
                    },
                  ]}
                  primaryAction={{
                    content: "Save",
                    disabled: !dirty || !isValid || isSubmitting,
                    onAction: handleSubmit,
                  }}
                />
              </Layout.Section>
            </Layout>
          </Page>
        </Form>
      )}
    </Formik>
  );
}


