import React, { useState, useEffect, useContext } from 'react';
const data = require('../../../common/data/base/_data');
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  EmptyState,
  Layout,
  Page,
  IndexTable,
  InlineStack,
  Spinner,
  Pagination,
  Button,
  Checkbox,
  Banner,
} from "@shopify/polaris";
import { MerchantContext } from "../../context/merchant";

const EmptyQRCodeState = ({ onAction }) => (
  <EmptyState
    heading="Create unique QR codes for your product"
    action={{
      content: "Create QR code",
      onAction,
    }}
    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
  >
    <p>Allow customers to scan codes and buy products using their phones.</p>
  </EmptyState>
);

function truncate(str, { length = 25 } = {}) {
  if (!str) return "";
  if (str.length <= length) return str;
  return str.slice(0, length) + "…";
}


const QRTable = ({ qrCodes, selectedQrCodes, handleSelect, selectAll, handleSelectAll }) => {

  const rows = qrCodes.map((qrCode) => (
    <QRTableRow 
      key={qrCode.uuid} 
      qrCode={qrCode} 
      selected={selectedQrCodes.includes(qrCode.uuid)} 
      handleSelect={handleSelect} 
    />
  ));

  return (
    <div
      style={{
        minHeight: '600px'
      }}
    >
      <IndexTable
        resourceName={{
          singular: "QR code",
          plural: "QR codes",
        }}
        itemCount={qrCodes.length}
        headings={[
          { title: <Checkbox label="All" checked={selectAll} onChange={handleSelectAll} /> },
          { title: "Title" },
          { title: "Destination Type" },
          { title: "Destination URL" },
          { title: "Date created" },
          { title: "Scans" },
        ]}
        selectable={false}
      >
          {rows}
      </IndexTable>
    </div>
  );
}

const QRTableRow = ({ qrCode, selected, handleSelect }) => (
  <IndexTable.Row id={qrCode.uuid}>
    <IndexTable.Cell>
      <Checkbox  checked={selected} onChange={() => handleSelect(qrCode.uuid)} />
    </IndexTable.Cell>    
    <IndexTable.Cell>
      <Link to={`app/qrcodes/${qrCode.uuid}`}>{truncate(qrCode.title)}</Link>
    </IndexTable.Cell>
    <IndexTable.Cell>
      {qrCode.destination_type}
    </IndexTable.Cell>
    <IndexTable.Cell>
      {truncate(qrCode.destination_url, {length: 50})}
    </IndexTable.Cell>
    <IndexTable.Cell>
      {new Date(qrCode.created_at).toDateString()}
    </IndexTable.Cell>
    <IndexTable.Cell>{qrCode.scans}</IndexTable.Cell>
  </IndexTable.Row>
);

function HomePage() {

  const [qrCodes, setQrCodes] = useState([]);
  const [selectedQrCodes, setSelectedQrCodes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // used to force refetch data after bulk delete
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const navigate = useNavigate();

  const { merchant } = useContext(MerchantContext);

  const isExplorerPlan = merchant?.subscription_plan_name === "Explorer";
  const remainingDays = 15 - merchant?.free_plan_spent || 0;
  const shopHandle = merchant?.shop_domain.replace("https://", "").split(".")[0];

  useEffect(() => {
    const fetchQrCodes = async () => {
      try {
        const response = await data.get(`${Project.api}api/v1/qrcodes/?page=${currentPage}`);
        setQrCodes(response.results);
        setTotalPages(response.total_pages);
      } catch (error) {
        console.error("Error fetching QR codes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQrCodes();
  }, [currentPage, fetchTrigger]);

  const handleBulkDelete = async () => {
    try {
      await data.post(`${Project.api}api/v1/qrcodes/bulk-delete/`, {
        uuids: selectedQrCodes
      });

      setQrCodes(qrCodes.filter(qrCode => !selectedQrCodes.includes(qrCode.uuid)));
  
      setSelectedQrCodes([]);
      setSelectAll(false);

      if (totalPages > 1) {
        setCurrentPage(1);
        setFetchTrigger(fetchTrigger + 1);
      }
  
    } catch (error) {
      console.error("Error deleting QR codes:", error);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedQrCodes([]);
    } else {
      setSelectedQrCodes(qrCodes.map(qr => qr.uuid));
    }
    setSelectAll(!selectAll);
  };

  const handleSelect = (uuid) => {
    if (selectedQrCodes.includes(uuid)) {
      setSelectedQrCodes(selectedQrCodes.filter(id => id !== uuid));
    } else {
      setSelectedQrCodes([...selectedQrCodes, uuid]);
    }
  };

  return (
    <Page>
      <ui-title-bar title="QR codes">
        <button onClick={() => navigate("/app/qrcodes/new")}>
          Create QR code
        </button>
        <button
          onClick={handleBulkDelete}
          disabled={selectedQrCodes.length === 0}
        >
          Delete Selected
        </button>
      </ui-title-bar>

      {isExplorerPlan && remainingDays >= 1 && remainingDays <= 3 && (
        <div style={{ marginBottom: '16px' }}>
          <Banner
            tone="warning"
            title={`You have ${remainingDays} day${remainingDays > 1 ? 's' : ''} remaining on your free plan.`}
            action={{
              content: 'Upgrade Now',
              onAction: () => {
                const upgradeUrl = `https://admin.shopify.com/store/${shopHandle}/charges/${Project.shopifyAppHandle}/pricing_plans`;
                window.parent.location.href = upgradeUrl;
              }
            }}
          />
        </div>
      )}

      <Layout>
        <Layout.Section>
          <Card padding="0">
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <Spinner accessibilityLabel="Loading QR codes" size="large" />
              </div>
            ) : qrCodes.length === 0 ? (
              <EmptyQRCodeState onAction={() => navigate("/app/qrcodes/new")} />
            ) : (
              <>
                <QRTable 
                  qrCodes={qrCodes} 
                  selectedQrCodes={selectedQrCodes}
                  handleSelect={handleSelect}
                  selectAll={selectAll}
                  handleSelectAll={handleSelectAll}
                />
                <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                  <InlineStack>
                    <Button 
                      onClick={() => {
                        setCurrentPage(1);
                      }}
                      disabled={currentPage === 1}
                    >
                      First
                    </Button>
                    <Pagination
                      label={`Page ${currentPage} of ${totalPages}`}
                      hasPrevious={currentPage > 1}
                      onPrevious={() => {
                        if (currentPage > 1) setCurrentPage(currentPage - 1);
                      }}
                      hasNext={currentPage < totalPages}
                      onNext={() => {
                        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
                      }}
                    />
                    <Button 
                      onClick={() => {
                        setCurrentPage(totalPages);
                      }} 
                      disabled={currentPage === totalPages}
                    >
                      Last
                    </Button>
                  </InlineStack>
                </div>
              </>
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default HomePage;
