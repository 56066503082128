const globalThis = typeof window === 'undefined' ? global : window
module.exports = global.Project = {
  env: 'prod',
  api: 'http://localhost:8000/',
  spritesheetLogos: 'https://prod-qrify-media.s3.amazonaws.com/qrcodes/logos/spritesheet-logos.png',
  spritesheetEyeTemplates: 'https://prod-qrify-media.s3.amazonaws.com/qrcodes/logos/spritesheet-eye-templates.png',
  spritesheetBodyTemplates: 'https://prod-qrify-media.s3.amazonaws.com/qrcodes/logos/spritesheet-body-templates.png',

  useSecureCookies: true,
  ...(globalThis.projectOverrides || {}),
}
