import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppProvider } from '@shopify/polaris';
import { ModalProvider } from './context/modal';
import { QrCodeTemplatesProvider } from './context/templates';
import { MerchantProvider } from './context/merchant';

import * as Sentry from '@sentry/browser';
import enTranslations from '@shopify/polaris/locales/en.json';
import '@shopify/polaris/build/esm/styles.css';
import routes from './routes'
import './styles/spritesheet.css'


if (typeof SENTRY_RELEASE_VERSION !== 'undefined' && Project.sentry && typeof Sentry !== 'undefined') {
  Sentry.init({
      dsn: Project.sentry,
      environment: Project.env,
      release: SENTRY_RELEASE_VERSION,
  });
}


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <AppProvider i18n={enTranslations}>
      <MerchantProvider>
        <QrCodeTemplatesProvider>
          <ModalProvider>
            <Router>{routes}</Router>
          </ModalProvider>
        </QrCodeTemplatesProvider>
      </MerchantProvider>
  </AppProvider>
);
