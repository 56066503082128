import React, { createContext, useState, useContext, ReactNode } from 'react';

const ModalContext = createContext(null);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modalContent, setModalContent] = useState<ReactNode>(null);

  const showModal = (content: ReactNode) => {
    return new Promise((resolve) => {
      setModalContent(
        React.cloneElement(content, {
          onClose: (result: any) => {
            resolve(result);
            setModalContent(null);
          },
        })
      );
    });
  };

  return (
    <ModalContext.Provider value={showModal}>
      {children}
      {modalContent}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
